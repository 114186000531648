import { useToast } from "@chakra-ui/react";
import { QueryKey, TQueryKey } from "app/types/common";
import { fetcher, mapQueryParams, postJSON } from "app/utils/fetchUtils";
import {
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";
import { WaScreenProps, WATemplate } from "app/types/message/whatsapp-template";

//Get-message gallery list

interface GalleryListProps {
    accountId?: string;
    search?: string;
    pageLimit?: number;
    tagNames?: string;
    industry?: string[];
    usecase?: string[];
}

const PAGE_SIZE = 10;

export const useMessageGalleryList = (props: GalleryListProps): UseInfiniteQueryResult<WaScreenProps[], Error> => {
    const { accountId, search, tagNames, industry, usecase, ...options } = props;
    const fetchMessageGalleryList: QueryFunction<WaScreenProps[], TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { search, tagNames, industry, usecase }] = queryKey;

        const queryParams = mapQueryParams({
            since: pageParam,
            limit: PAGE_SIZE,
            search,
            tagNames,
            industry,
            usecase,
        });

        return fetcher(`/api/wa-template-gallery?${queryParams}`);
    };

    const infiniteQueryResult = useInfiniteQuery<WaScreenProps[], Error, WaScreenProps[], TQueryKey>(
        [QueryKey.MessageGalleryList, { search, tagNames, industry, usecase }],
        fetchMessageGalleryList,
        {
            ...options,
            getNextPageParam: (lastPage) => {
                if (!lastPage?.length) return undefined;
                return lastPage[lastPage.length - 1] ? lastPage[lastPage.length - 1].id : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

//create template message from message gallery

interface WaTemplateProps {
    accountId: string;
    channelId: string;
    waTemplateGalleryId: string;
    forceCreate?: boolean;
    buttonUrl?: string;
    phoneNumber?: string;
}

interface usePostMessageTemplateProps {
    accountId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

interface IWATemplateCreateRes {
    status: "exist" | "create" | "forceCreate";
    template: WATemplate;
}

type UsePostCreateTemplate<InputType> = UseMutationResult<IWATemplateCreateRes, Error, InputType>;

export const UsePostCreateTemplate = <InputType extends WaTemplateProps>(
    props: usePostMessageTemplateProps
): UsePostCreateTemplate<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ConvertMessageTemplate, { accountId }];

    const createTemplate = (data: InputType) => {
        const query = mapQueryParams({
            forceCreate: data.forceCreate,
        });
        return postJSON<InputType>(`/api/wa-template-gallery/WATemplate?${query}`, { ...data });
    };

    const mutationResult = useMutation<IWATemplateCreateRes, Error, InputType>(createTemplate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: " Template Created Successfully",
                    status: "success",
                });
            }
            onSuccess?.();
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//get one TemplateGallery

interface UseTemplateGalleryTemplateProps extends UseQueryOptions<WaScreenProps, Error, WaScreenProps, TQueryKey> {
    messageGalleryId: string;
}

const fetchMessageGalleryResult: QueryFunction<WaScreenProps, TQueryKey> = ({ queryKey }) => {
    const [, { messageGalleryId }] = queryKey;

    return fetcher(`api/wa-template-gallery/${messageGalleryId}`);
};

export const UseTemplateGalleryTemplate = (
    props: UseTemplateGalleryTemplateProps
): UseQueryResult<WaScreenProps, Error> => {
    const { messageGalleryId, ...options } = props;

    const result = useQuery<WaScreenProps, Error, WaScreenProps, TQueryKey>(
        [QueryKey.GetGalleryTemplate, { messageGalleryId }],
        fetchMessageGalleryResult,
        options
    );

    return result;
};
