import Auth from "app/components/Auth";
import lazyWithRetry from "app/components/Lazy/LazyWithRetry";
import useLocalStorage from "app/hooks/useLocalStorage";
import NavigationLayout from "app/layouts/NavigationLayout";
import { initAuth } from "app/modules/authed";
import { initEnvironment } from "app/modules/environment";
import AlertModal, { AlertType } from "app/screens/Auth/AlertModal";
import Logout from "app/screens/Auth/Logout";
import SignIn from "app/screens/Auth/SignIn";
import { Authed } from "app/types/common";
import { gbAPI, refreshAuthLogic } from "app/utils/fetchUtils";
import { Location } from "app/utils/history";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AMPLITUDE_DEVELOPMENT_API_KEY_ENV } from "environment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AdminRoutes from "./appRoutes/AdminRoutes";
import ProtectedRoute from "./appRoutes/ProtectedRoute";
import PublicRoute from "./appRoutes/PublicRoute";
import AuthProvider from "./AuthProvider";
import AlertNetworkConnection from "./components/AlertNetworkConnection";
import NewVersionAlert from "./components/NewVersionAlert";
import GBProductTour from "./gbComponents/Misc/GBProductTour";
import * as prdAnalyticsTracker from "./hooks/productAnalytics/tracker";
import { sanitizeUser } from "./hooks/productAnalytics/util";
import useUserRole from "./hooks/useUserRole";
import DefaultLayout from "./layouts/DefaultLayout";
import { RootState } from "./modules";
import { CalendlyHome } from "./screens/Account/Integration/Calendly/CalendlyHome";
import ZohoHelpWidget from "./screens/Widget/ZohoHelpWidget";
import { useBackgroundNotification } from "./utils/react-helpers";
import withSuspense from "./WithSuspense";
import { loadLanguage } from "lang/i18n";
import { SupportedLocaleCodeType } from "lang/types/common";
import { LanguageIso } from "./types";
import MiitelWidget from "./screens/Widget/MiitelWidget";

dayjs.extend(utc);
dayjs.extend(timezone);
const LeadSquaredHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/LeadSquared/LeadSquaredHome"))
);

const CleverTapHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/CleverTap/CleverTapHome"))
);
const SignUp = withSuspense(lazyWithRetry(() => import("./screens/Auth/Signup")));
const SSOLoginInIFrame = withSuspense(lazyWithRetry(() => import("./screens/OAuthCallback/SSOLoginInIFrame")));
const SignUpVerifyEmail = withSuspense(lazyWithRetry(() => import("./screens/Auth/Signup/SignUpVerifyEmail")));
const ConversationV2 = withSuspense(lazyWithRetry(() => import("./screens/ConversationV2")));
const AccountDetails = withSuspense(lazyWithRetry(() => import("./screens/Account/AccountDetails")));
const IntegrationFields = withSuspense(lazyWithRetry(() => import("./screens/Account/Admin/IntegrationFields")));
const ApiKey = withSuspense(lazyWithRetry(() => import("./screens/Account/ApiDetails")));
const BackStage = withSuspense(lazyWithRetry(() => import("./screens/Account/BackStage")));
const CannedResponse = withSuspense(lazyWithRetry(() => import("./screens/Account/CannedResponse")));
const Credentials = withSuspense(lazyWithRetry(() => import("./screens/Account/Credentials")));
const Fields = withSuspense(lazyWithRetry(() => import("./screens/Account/Fields")));
const IntegrationHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/Home")));
const Roles = withSuspense(lazyWithRetry(() => import("./screens/Account/MembersAndRoles")));
const TagList = withSuspense(lazyWithRetry(() => import("./screens/Account/Tag")));
const UserDetails = withSuspense(lazyWithRetry(() => import("./screens/Account/UserDetails")));
const Webhook = withSuspense(lazyWithRetry(() => import("./screens/Account/Webhook")));
const WhatsappOrder = withSuspense(lazyWithRetry(() => import("./screens/WhatsappOrder/index")));
const WooCommerceHome = withSuspense(lazyWithRetry(() => import("./screens/Account/WooCommerce")));
const HubspotHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Hubspot")));
const PipedriveHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Pipedrive")));
const CashFreeWrapper = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/CashFree/CashfreeWrapper"))
);

const ShipwayHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/Shipway/ShipwayHome")));
const AuthHome = withSuspense(lazyWithRetry(() => import("./screens/Auth/Home")));
const Invite = withSuspense(lazyWithRetry(() => import("./screens/Auth/Invite")));
const SocialInvite = withSuspense(lazyWithRetry(() => import("./screens/Auth/SocialSignUpInvite")));

const ResetPassword = withSuspense(lazyWithRetry(() => import("./screens/Auth/ResetPassword")));
const UpdatePassword = withSuspense(lazyWithRetry(() => import("./screens/Auth/UpdatePassword")));
const AccountInitialSetup = withSuspense(lazyWithRetry(() => import("./screens/Auth/SelfServe/AccountInitialSetup")));
// const IntroVideo = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/IntroVideo")));
const Connect360Page = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/New/Connect360Page")));
const Connect360PageInApp = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/New/Connect360PageInApp")));
const OnboardingNew = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/New")));
const ListWARegistration = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/TechProvider")));
const ListWARegistrationFullScreen = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/TechProvider/TechPartnerChannelList"))
);
const PartnerClientOnboarding = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/New/PartnerClientOnboarding"))
);
const WATemplateHome = withSuspense(lazyWithRetry(() => import("./screens/Account/WATemplatesV2/WATemplateHome")));
const WACreateTemplate = withSuspense(lazyWithRetry(() => import("./screens/Account/WATemplatesV2/WACreateTemplate")));
const AdsMainPage = withSuspense(lazyWithRetry(() => import("./screens/Ads/C2wa")));
const UpgradeScreen = withSuspense(lazyWithRetry(() => import("./screens/Auth/UpgradeScreen")));
const BotUnified = withSuspense(lazyWithRetry(() => import("./screens/BotUnified")));
const ContactUnified = withSuspense(lazyWithRetry(() => import("./screens/ContactUnified")));
const BotV2 = withSuspense(lazyWithRetry(() => import("./screens/BotV2")));
const AIFlowBot = withSuspense(lazyWithRetry(() => import("./screens/BotV2/AIBotFlow/AIBotFlowBuilder")));
const BotConsole = withSuspense(lazyWithRetry(() => import("./screens/BotConsole")));
const FlowBuilder = withSuspense(lazyWithRetry(() => import("./screens/BotFlowBuilder/Builder")));
const BotBuilderV1 = withSuspense(lazyWithRetry(() => import("./screens/BotV1/Builder")));

const Channels = withSuspense(lazyWithRetry(() => import("./screens/Channels")));
const AddWebChannel = withSuspense(lazyWithRetry(() => import("./screens/Channels/AddWebChannel")));
const ChannelsListLeftMenu = withSuspense(lazyWithRetry(() => import("./screens/Channels/ChannelsList")));
const EmailChannel = withSuspense(lazyWithRetry(() => import("./screens/Channels/Email/EmailChannel")));
const EditWAProfile = withSuspense(lazyWithRetry(() => import("./screens/Channels/Whatsapp")));
const ChooseChannelProvider = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/New/ChooseChannelProvider"))
);
const ConnectChannelProvider = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/New/ConnectChannelProvider"))
);
const TechPartnerRegisterPhoneNo = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/TechProvider/RegisterPhoneNo"))
);
const AddWhatsappChannel = withSuspense(lazyWithRetry(() => import("./screens/Channels/Whatsapp/AddWhatsappChannel")));
const Dialog360IntegratedOnboarding = withSuspense(
    lazyWithRetry(() => import("./screens/Channels/Whatsapp/Dialog360IntegratedOnboarding"))
);
const Dialog360IntegratedOnboardingFullScreen = withSuspense(
    lazyWithRetry(() => import("./screens/Channels/Whatsapp/Dialog360IntegratedOnboarding/Dialog360ChannelList"))
);
const UpdateClientToGallabox = withSuspense(
    lazyWithRetry(() => import("./screens/Channels/Whatsapp/Dialog360IntegratedOnboarding/UpdateClientToGallabox"))
);

const StripeHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/StripeIntegration/StripeHome"))
);

const ShopfloHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/Shopflo/ShopfloHome")));

const SelectProvider = withSuspense(lazyWithRetry(() => import("./screens/Channels/Whatsapp/SelectProvider")));
const ApplyForWhatsappNumber = withSuspense(
    lazyWithRetry(() => import("./screens/Channels/Whatsapp/ApplyForWhatsappNumber"))
);
const FBCatalogs = withSuspense(lazyWithRetry(() => import("./screens/FacebookCatalogs")));
const CatalogDetails = withSuspense(lazyWithRetry(() => import("./screens/Channels/Whatsapp/Catalogs/CatalogDetails")));
const WAConfiguration = withSuspense(lazyWithRetry(() => import("./screens/Channels/Whatsapp/configurations")));
const ContactPreview = withSuspense(lazyWithRetry(() => import("./screens/ContactPreview")));
const ImportContacts = withSuspense(lazyWithRetry(() => import("./screens/Contacts/ImportContacts")));
const ImportContactMappingPage = withSuspense(
    lazyWithRetry(() => import("./screens/ImportContact/pages/ImportContactMapping/ImportContactMappingPage"))
);
const ImportContactHistory = withSuspense(
    lazyWithRetry(() => import("./screens/ImportContact/pages/ImportContactHistory"))
);
const ImportNewContacts = withSuspense(lazyWithRetry(() => import("./screens/ImportContact/pages/ImportNewContacts")));

const NewConversationByPhone = withSuspense(
    lazyWithRetry(() => import("./screens/ConversationV2/NewConversationByPhone"))
);
const Dashboard = withSuspense(lazyWithRetry(() => import("./screens/Dashboard")));
const OAuthCallback = withSuspense(lazyWithRetry(() => import("./screens/OAuthCallback/OAuthCallback")));
const Dialog360Callback = withSuspense(
    lazyWithRetry(() => import("./screens/Channels/Whatsapp/Dialog360IntegratedOnboarding/Dialog360Callback"))
);
const PaymentStatus = withSuspense(lazyWithRetry(() => import("./screens/PaymentStatus/PaymentStatus")));
const PlanAndSubscription = withSuspense(lazyWithRetry(() => import("./screens/Plan")));
const PaymentSetup = withSuspense(lazyWithRetry(() => import("./screens/Plan/PaymentSetup")));
const Report = withSuspense(lazyWithRetry(() => import("./screens/Report")));
const ActivateSandBox = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/Sandbox/SandboxActivation")));
const SandboxOrOwnNumber = withSuspense(lazyWithRetry(() => import("./screens/Onboarding/Sandbox/SandboxOrOwnNumber")));
const MobileAppDownloadView = withSuspense(
    lazyWithRetry(() => import("./screens/Onboarding/Sandbox/MobileAppDownloadView"))
);
const SandboxContact = withSuspense(lazyWithRetry(() => import("./screens/Sandbox/components/SandboxContact")));
const SelfServe = withSuspense(lazyWithRetry(() => import("./screens/Sandbox/SelfServe")));
const BotBuilderFullView = withSuspense(lazyWithRetry(() => import("./screens/BotFlowBuilder/BotBuilderFullView")));
const BotV2FullView = withSuspense(lazyWithRetry(() => import("./screens/BotV2/BotV2FullView")));
const BotTemplatePreview = withSuspense(lazyWithRetry(() => import("./screens/Bot/BotTemplate/BotTemplatePreview")));
const FBLeadsHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Fbleads")));
const ConversationReport = withSuspense(lazyWithRetry(() => import("./screens/ConversationReport/ConversationReport")));
const WhatsappFlows = withSuspense(lazyWithRetry(() => import("./screens/WhatsappFlows")));
const WhatsappFlowLogsExport = withSuspense(
    lazyWithRetry(() => import("./screens/WhatsappFlows/pages/WhatsappFlowLogs/WhatsappFlowLogsExport"))
);
const WhatsappFlowConsole = withSuspense(
    lazyWithRetry(() => import("./screens/WhatsappFlows/pages/WhatsappFlowConsole"))
);

const MessageTracking = withSuspense(lazyWithRetry(() => import("./screens/Account/MessageTracker")));
const ListSeq = withSuspense(lazyWithRetry(() => import("./screens/Sequence/Dashboard")));
const SequenceReport = withSuspense(lazyWithRetry(() => import("./screens/Sequence/Report")));
const CreateSequence = withSuspense(lazyWithRetry(() => import("./screens/Sequence/CreateSequence")));
const FlexiList = withSuspense(lazyWithRetry(() => import("./screens/FlexiList")));
const FlexiListView = withSuspense(lazyWithRetry(() => import("./screens/FlexiList/FlexiListView")));
const CreateOrEditFlexiList = withSuspense(lazyWithRetry(() => import("./screens/FlexiList/CreateOrEditFlexiList")));
const GetStarted = withSuspense(lazyWithRetry(() => import("./screens/Account/AccountOnboarding/GetStarted")));
const ExtendFreeTrial = withSuspense(lazyWithRetry(() => import("./screens/Auth/ExtendTrial")));

const PaymentsConfig = withSuspense(lazyWithRetry(() => import("./screens/Payments/PaymentConfig")));
const Payments = withSuspense(lazyWithRetry(() => import("./screens/Payments")));

const AuditLog = withSuspense(lazyWithRetry(() => import("./screens/AuditLog/AuditLog")));
const IPConfig = withSuspense(lazyWithRetry(() => import("./screens/IPConfig")));
const IPNotAllowed = withSuspense(lazyWithRetry(() => import("./screens/Auth/IPNotAllowed")));

const MoEngage = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/MoEngage")));
const Fyno = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/Fyno")));
const ShipRocketWrapper = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/ShipRocket/ShipRocketHome"))
);
const WebEngage = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/WebEngage")));
const WebHookHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/WebHookIntegration/WebHookHome"))
);
const WooCommerceAbandonCartHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/WooCommerceAbandonCart"))
);
const WooCommerceCartFlowHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/WooCommerceCartFlow"))
);
const ZohoBooksHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/ZohoBooks/ZohoBooksHome"))
);
const ZohoCRMHome = withSuspense(
    lazyWithRetry(() => import("./screens/Account/Integration/ZohoCRMSignals/ZohoCRMHome"))
);
const MiitelHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Integration/Miitel/MiitelHome")));
const RazorPayWrapper = withSuspense(lazyWithRetry(() => import("./screens/Account/RazorPay")));
const ShopifyCallback = withSuspense(lazyWithRetry(() => import("./screens/Account/Shopify/ShopifyCallback")));
const ShopifyHome = withSuspense(lazyWithRetry(() => import("./screens/Account/Shopify/ShopifyHome")));
const AccountSuspend = withSuspense(lazyWithRetry(() => import("./screens/Auth/AccountSuspend")));
const TwoFA = withSuspense(lazyWithRetry(() => import("./screens/Auth/SignIn/TwoFA")));
const BroadCastActiveExport = withSuspense(
    lazyWithRetry(() => import("./screens/Broadcast/BroadCastHistory/BroadCastActiveExport"))
);
const BroadCastV2Home = withSuspense(
    lazyWithRetry(() => import("./screens/Broadcast/BroadCastHistory/BroadCastHistory"))
);
const BroadCastReport = withSuspense(
    lazyWithRetry(() => import("./screens/Broadcast/BroadCastReport/BroadCastReport"))
);
const BroadCastContextProvider = withSuspense(
    lazyWithRetry(() => import("./screens/Broadcast/CreateBroadCast/BroadCastContext"))
);
const KnowledgeBase = withSuspense(lazyWithRetry(() => import("./screens/KnowledgeBase")));
const PaymentsActiveExport = withSuspense(
    lazyWithRetry(() => import("./screens/Payments/Dashboard/PaymentActiveExport"))
);
const PrepaidCreditHome = withSuspense(lazyWithRetry(() => import("./screens/PrepaidCredit")));
const RedirectPage = withSuspense(lazyWithRetry(() => import("./screens/Redirect")));
const PartnerDashboard = withSuspense(lazyWithRetry(() => import("./screens/PartnerHub/pages/PartnerDashboard/index")));

const AppRoutes: React.FC<{ authed: Authed }> = ({ ...routeProps }) => {
    return (
        <Switch>
            <ProtectedRoute {...routeProps} path="/logout" component={Logout} />

            <PublicRoute
                {...routeProps}
                path="/login"
                component={DefaultLayout}
                componentProps={{ component: SignIn }}
                name="LOGIN"
            />
            <PublicRoute
                {...routeProps}
                path="/ip-not-allowed"
                component={DefaultLayout}
                componentProps={{ component: IPNotAllowed }}
                name="IP_NOT_ALLOWED"
            />
            <PublicRoute
                {...routeProps}
                path="/signup"
                component={DefaultLayout}
                componentProps={{ component: SignUp }}
                name="SIGNUP"
            />
            <PublicRoute
                {...routeProps}
                exact
                path="/auth/:status"
                component={DefaultLayout}
                componentProps={{ component: Auth }}
            />
            <PublicRoute
                {...routeProps}
                path="/two-factor-auth"
                component={TwoFA}
                componentProps={{ page: "verify-otp" }}
                name="SIGNUP_VERIFY_EMAIL"
            />
            <ProtectedRoute
                {...routeProps}
                path="/switch-two-factor-auth"
                component={TwoFA}
                componentProps={{ page: "verify-otp" }}
                name="PARTNER_SWITCH_2FA_AUTH"
            />
            <PublicRoute
                {...routeProps}
                path="/recover-totp"
                component={TwoFA}
                componentProps={{ page: "recover-totp" }}
                name="SIGNIN_RECOVER_TOTP"
            />
            <ProtectedRoute
                {...routeProps}
                path="/switch-recover-totp"
                component={TwoFA}
                componentProps={{ page: "recover-totp" }}
                name="PARTNER_SWITCH_RECOVER_TOTP"
            />
            <ProtectedRoute
                {...routeProps}
                path="/enable-two-fa"
                component={TwoFA}
                componentProps={{ page: "nudge" }}
                name="SIGNUP_VERIFY_EMAIL"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/new-conversation/phone/:phone", "/conversations/phone/:phone"]}
                component={NavigationLayout}
                componentProps={{ component: NewConversationByPhone }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/analytics/report/:reportType"
                component={NavigationLayout}
                componentProps={{ component: Report }}
                name="REPORT_TABLE"
            />
            <ProtectedRoute
                {...routeProps}
                path={[
                    "/conversations/contacts/:contactId",
                    "/conversations/:conversationId",
                    "/conversations",
                    "/new-conversation/:contactId",
                ]}
                component={NavigationLayout}
                componentProps={{ component: ConversationV2 }}
                name="CONVERSATION_LIST_TAB"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/whatsapp-order"
                component={NavigationLayout}
                componentProps={{ component: WhatsappOrder }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/contacts/import/simple"
                component={NavigationLayout}
                componentProps={{ component: ImportContacts }}
                name="CONTACT-SIMPLE-IMPORT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/contacts/import/advanced"
                component={NavigationLayout}
                componentProps={{ component: ImportContactHistory }}
                name="CONTACT-ADVANCED-IMPORT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/contacts/import/new"
                component={NavigationLayout}
                componentProps={{ component: ImportNewContacts }}
                name="CONTACT-ADVANCED-IMPORT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/contacts/import/:importId"
                component={NavigationLayout}
                componentProps={{ component: ImportNewContacts, defaultPage: "upload-file" }}
                name="CONTACT-ADVANCED-IMPORT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/contacts/import/advanced/:importId/preview"
                component={NavigationLayout}
                componentProps={{ component: ImportContactMappingPage }}
                name="CONTACT-ADVANCED-IMPORT-PREVIEW"
            />
            <ProtectedRoute
                {...routeProps}
                path="/broadcast"
                exact
                component={NavigationLayout}
                componentProps={{
                    component: BroadCastV2Home,
                }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/broadcast/new", "/broadcast/new/:channelId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: BroadCastContextProvider }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/broadcast/broadcastreport/:broadcastId"
                exact
                component={NavigationLayout}
                componentProps={{ component: BroadCastReport }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/broadcast/active-export"
                exact
                component={NavigationLayout}
                componentProps={{ component: BroadCastActiveExport }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/tags"
                exact
                component={NavigationLayout}
                componentProps={{ component: TagList }}
                name="TAG-LIST-TABLE"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/integration", "/integration/razorpay/error"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: IntegrationHome }}
                name="INTEGRATION-HOME"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/integration/fbleads", "/integration/fbleads/edit/:integrationId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: FBLeadsHome }}
                name="INTEGRATION_FBLEADS"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/integration/razorpay", "/integration/razorpay/edit/:integrationId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: RazorPayWrapper }}
                name="INTEGRATION_RAZORPAY"
            />
            <ProtectedRoute
                {...routeProps}
                path="/sequence"
                exact
                component={NavigationLayout}
                componentProps={{ component: ListSeq }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/sequence/:sequenceId/report"
                exact
                component={NavigationLayout}
                componentProps={{ component: SequenceReport }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/sequence/new", "/sequence/edit/:sequenceId", "/sequence/edit/:sequenceId/:location"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: CreateSequence }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/flexi-list"
                exact
                component={NavigationLayout}
                componentProps={{ component: FlexiList }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/flexi-list/:listId"
                exact
                component={NavigationLayout}
                componentProps={{ component: FlexiListView }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/flexi-list/new"
                exact
                component={NavigationLayout}
                componentProps={{ component: CreateOrEditFlexiList, componentProps: { mode: "create" } }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels"
                exact
                component={NavigationLayout}
                componentProps={{ component: Channels }}
                name="CHANNEL-HOME"
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/:channelType"
                exact
                component={NavigationLayout}
                componentProps={{ component: ChannelsListLeftMenu }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/dashboard", "/dashboard/:tabId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: Dashboard }}
                name="DASHBOARD"
            />
            <ProtectedRoute
                {...routeProps}
                path="/conversation-report"
                exact
                component={NavigationLayout}
                componentProps={{ component: ConversationReport }}
                name="CONVERSATION_LIST_TABLE"
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/create"
                component={DefaultLayout}
                componentProps={{ component: ChooseChannelProvider }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/connect-your-number"
                component={DefaultLayout}
                componentProps={{ component: ConnectChannelProvider }}
            />
            {/* FIXME: Unused */}
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/register"
                component={NavigationLayout}
                componentProps={{ component: TechPartnerRegisterPhoneNo }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/whatsapp-flows"
                exact
                component={NavigationLayout}
                componentProps={{ component: WhatsappFlows }}
                name="CONVERSATION_LIST_TABLE"
            />
            {/* <ProtectedRoute
                {...routeProps}
                path="/whatsapp-flows/:whatsappFlowId"
                exact
                component={NavigationLayout}
                componentProps={{ component: WhatsappFlowLogs }}
                name="CONVERSATION_LIST_TABLE"
            /> */}
            <ProtectedRoute
                {...routeProps}
                path={["/whatsapp-flows/:whatsappFlowId", "/whatsapp-flows/:whatsappFlowId/:tabId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: WhatsappFlowConsole }}
                name="CONVERSATION_LIST_TABLE"
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/add"
                component={NavigationLayout}
                componentProps={{ component: AddWhatsappChannel }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/select"
                component={NavigationLayout}
                componentProps={{ component: SelectProvider }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/dialog360"
                component={NavigationLayout}
                exact
                componentProps={{ component: Dialog360IntegratedOnboarding }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/meta-tech-partner"
                component={NavigationLayout}
                componentProps={{ component: ListWARegistration }}
                exact
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/meta-tech-partner/new"
                component={ListWARegistrationFullScreen}
                componentProps={{ isOnboarding: true }}
                exact
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/dialog360/new"
                component={Dialog360IntegratedOnboardingFullScreen}
                componentProps={{ isOnboarding: true }}
                exact
            />
            <ProtectedRoute
                {...routeProps}
                path="/channels/whatsapp/dialog360/updateClient"
                component={NavigationLayout}
                exact
                componentProps={{ component: UpdateClientToGallabox }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/channels/web/add", "/channels/web/update/:channelId"]}
                component={NavigationLayout}
                componentProps={{ component: AddWebChannel }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/channels/email/add", "/channels/email/update/:channelId"]}
                component={NavigationLayout}
                componentProps={{ component: EmailChannel }}
            />
            <ProtectedRoute
                {...routeProps}
                path={[
                    "/channels/whatsapp/:channelId/chat-configurations",
                    "/channels/web/:channelId/chat-configurations",
                ]}
                component={NavigationLayout}
                componentProps={{ component: WAConfiguration }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/channels/whatsapp/:channelId", "/channels/whatsapp/:channelId/:tabId"]}
                component={NavigationLayout}
                componentProps={{ component: EditWAProfile }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/fields"
                component={NavigationLayout}
                componentProps={{ component: Fields }}
                name="ADDITIONAL_FIELDS"
            />
            <ProtectedRoute
                {...routeProps}
                path="/account"
                component={NavigationLayout}
                componentProps={{ component: AccountDetails }}
                name="ACCOUNT_DETAILS"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/cannedresponse", "/cannedresponse/add", "/cannedresponse/edit/:templateId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: CannedResponse }}
                name="CANNED_RESPONSE"
            />
            <ProtectedRoute
                {...routeProps}
                path={[
                    "/whatsapp-template-messagesv2/new",
                    "/whatsapp-template-messagesv2/new/Gallery/:templateGalleryId",
                    "/whatsapp-template-messagesv2/new/duplicate/:templateId",
                    "/whatsapp-template-messagesv2/template/:templateId",
                ]}
                exact
                component={NavigationLayout}
                componentProps={{ component: WACreateTemplate }}
                name="WA_TEMPLATE_MESSAGE_LIST_V2/new"
            />
            <ProtectedRoute
                {...routeProps}
                path="/whatsapp-template-messagesv2"
                exact
                component={NavigationLayout}
                componentProps={{ component: WATemplateHome }}
                name="WA_TEMPLATE_MESSAGE_LIST_V2"
            />
            <ProtectedRoute
                {...routeProps}
                path="/facebook-catalogs/list/:catalogId"
                exact
                component={NavigationLayout}
                componentProps={{ component: CatalogDetails }}
                name="FACEBOOK_CATALOG_DETAILS"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/facebook-catalogs/:tabName", "/facebook-catalogs"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: FBCatalogs }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/profile"
                component={NavigationLayout}
                componentProps={{ component: UserDetails }}
                name="USER_PROFILE"
            />
            <ProtectedRoute
                {...routeProps}
                path="/apikey"
                component={NavigationLayout}
                componentProps={{ component: ApiKey }}
                name="APIKEY_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                path="/credentials"
                component={NavigationLayout}
                componentProps={{ component: Credentials }}
                name="CREDENTIALS_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                path={[
                    "/contacts/:backstageType",
                    "/conversation/report/:backstageType",
                    "/sequence/report/:backstageType",
                    "/ctwa/report/:backstageType",
                ]}
                component={NavigationLayout}
                componentProps={{ component: BackStage }}
                name="BACKSTAGE_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                path="/webhook"
                component={NavigationLayout}
                componentProps={{ component: Webhook }}
                name="WEBHOOK_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                path="/credentials"
                component={NavigationLayout}
                componentProps={{ component: Credentials }}
            />
            <ProtectedRoute
                {...routeProps}
                path={["/roles", "/roles/:tabId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: Roles }}
                name="USER_INVITE_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                path="/contact-preview"
                component={ContactPreview}
                componentProps={{ component: Roles }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/bot-builder"
                component={NavigationLayout}
                componentProps={{ component: BotBuilderV1 }}
                name="BOT_LIST"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/bots/:botId/flows/:flowId"
                component={NavigationLayout}
                componentProps={{ component: FlowBuilder }}
                name="BOT_FLOWS_BUILDER"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/bots/:botId/:botName/full-view"
                component={NavigationLayout}
                componentProps={{ component: BotBuilderFullView }}
                name="BOT_FULL_VIEW"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/botv2/:botId/:botName/full-view"
                component={NavigationLayout}
                componentProps={{ component: BotV2FullView }}
                name="BOT_V2_FULL_VIEW"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/bots", "/bot-templates", "/bots/create/:trigger"]}
                component={NavigationLayout}
                componentProps={{ component: BotUnified }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/contacts", "/companies", "/segments"]}
                component={NavigationLayout}
                componentProps={{ component: ContactUnified }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/bot-templates-preview/:botTemplateId/:botTemplateName"
                component={NavigationLayout}
                componentProps={{ component: BotTemplatePreview }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/bots/:botId", "/bots/:botId/:tabId"]}
                component={NavigationLayout}
                componentProps={{ component: BotConsole }}
                name="BOT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/analytics/report/:reportType"
                component={NavigationLayout}
                componentProps={{ component: Report }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/get-dedicated-number"
                component={DefaultLayout}
                componentProps={{ component: SelfServe }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/channel-type-selection"
                component={DefaultLayout}
                componentProps={{ component: SandboxOrOwnNumber }}
                name="CHANNEL_TYPE_SELECTION"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/sandbox", "/sandbox/:channelId"]}
                exact
                component={DefaultLayout}
                componentProps={{ component: ActivateSandBox }}
                name="SANDBOX"
            />
            <ProtectedRoute
                {...routeProps}
                path="/mobile-download"
                exact
                component={DefaultLayout}
                componentProps={{ component: MobileAppDownloadView }}
                name="MOBILE_DOWNLOAD"
            />
            <ProtectedRoute
                {...routeProps}
                path="/apply-for-wa-number"
                component={DefaultLayout}
                componentProps={{ component: ApplyForWhatsappNumber }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/contact/:channelId"
                component={DefaultLayout}
                componentProps={{ component: SandboxContact }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/home"
                component={DefaultLayout}
                componentProps={{ component: AuthHome }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/account-disconnect"
                component={DefaultLayout}
                componentProps={{ component: AccountSuspend }}
                name="ACCOUNT_SUSPENDED"
            />
            <ProtectedRoute
                {...routeProps}
                path="/upgrade"
                component={DefaultLayout}
                componentProps={{ component: UpgradeScreen }}
                name="UPGRADE_PLAN"
            />
            <ProtectedRoute
                {...routeProps}
                path="/plan-and-subscription"
                component={NavigationLayout}
                componentProps={{ component: PlanAndSubscription }}
                name="PLAN_AND_SUBSCRIPTION"
            />
            <ProtectedRoute
                {...routeProps}
                path="/payment-setup"
                component={NavigationLayout}
                componentProps={{ component: PaymentSetup }}
                name="PAYMENT_SETUP"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/payment-status/:accountId/:planId"
                component={NavigationLayout}
                componentProps={{ component: PaymentStatus }}
                name="PAYMENT_STATUS"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/message-credits", "/message-credits/export"]}
                component={NavigationLayout}
                componentProps={{ component: PrepaidCreditHome }}
                name="MESSAGE_CREDITS"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/integration/shopify", "/integration/shopify/edit/:integrationId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: ShopifyHome }}
                name="SHOPIFY_HOME"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={"/integration/shopify/callback"}
                component={NavigationLayout}
                componentProps={{ component: ShopifyCallback }}
            />
            <Route path={"/admin"}>
                <AdminRoutes {...routeProps} />
            </Route>
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/woocommerce", "/integration/woocommerce/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: WooCommerceHome }}
                name="INTEGRATION_WOOCOMMERCE"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={[
                    "/integration/woocommerce_abandoned_cart",
                    "/integration/woocommerce_abandoned_cart/edit/:integrationId",
                ]}
                component={NavigationLayout}
                componentProps={{ component: WooCommerceAbandonCartHome }}
                name="INTEGRATION_WOOCOMMERCE_ABANDONED_CART"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/hubspot", "/integration/hubspot/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: HubspotHome }}
                name="INTEGRATION_HUBSPOT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={[
                    "/integration/zoho-books",
                    "/integration/zoho-books/error",
                    "/integration/zoho-books/edit/:integrationId",
                    "/integration/zohobooks",
                    "/integration/zohobooks/edit/:integrationId",
                ]}
                component={NavigationLayout}
                componentProps={{ component: ZohoBooksHome }}
                name="INTEGRATION_ZOHOBOOKS"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/woocommerce_cartflow", "/integration/woocommerce_cartflow/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: WooCommerceCartFlowHome }}
                name="INTEGRATION_WOOCOMMERCE_CARTFLOW"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={[
                    "/integration/zohoCRM",
                    "/integration/zohoCRM/edit/:integrationId",
                    "/integration/zohoCRM/error",
                ]}
                component={NavigationLayout}
                componentProps={{ component: ZohoCRMHome }}
                name="INTEGRATION_ZOHO_CRM"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/miitel", "/integration/miitel/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: MiitelHome }}
                name="INTEGRATION_ZOHO_CRM"
            />

            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/cashfree", "/integration/cashfree/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: CashFreeWrapper }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/shiprocket", "/integration/shiprocket/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: ShipRocketWrapper }}
                name="SHIPROCKET_INTEGRATION"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/shipway", "/integration/shipway/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: ShipwayHome }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/message-tracking"
                component={NavigationLayout}
                componentProps={{ component: MessageTracking }}
                name="MESSAGE_TRACKING"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/leadsquared", "/integration/leadsquared/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: LeadSquaredHome }}
                name="INTEGRATION_LEADSQUARED"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/cleverTap", "/integration/cleverTap/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: CleverTapHome }}
                name="INTEGRATION_LEADSQUARED"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/stripe", "/integration/stripe/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: StripeHome }}
                name="INTEGRATION_LEADSQUARED"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/shopFlo", "/integration/shopFlo/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: ShopfloHome }}
                name="INTEGRATION_LEADSQUARED"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={[
                    "/integration/genericWebhook",
                    "/integration/genericWebhook/edit/:integrationId",
                    "/integration/genericWebhook/edit/:integrationId/workflow/:workflowId",
                ]}
                component={NavigationLayout}
                componentProps={{ component: WebHookHome }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/webengage", "/integration/webengage/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: WebEngage }}
                name="INTEGRATION_WEBENGAGE"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/moengage", "/integration/moengage/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: MoEngage }}
                name="INTEGRATION_MOENGAGE"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/fyno", "/integration/fyno/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: Fyno }}
                name="INTEGRATION_FYNO"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path={[
                    "/integration/calendly",
                    "/integration/calendly/edit/:integrationId",
                    "/integration/calendly/error",
                ]}
                component={NavigationLayout}
                componentProps={{ component: CalendlyHome }}
                name="INTEGRATION_CALENDLY"
            />
               <ProtectedRoute
                {...routeProps}
                exact
                path={["/integration/pipedrive", "/integration/pipedrive/edit/:integrationId"]}
                component={NavigationLayout}
                componentProps={{ component: PipedriveHome }}
                name="INTEGRATION_PIPEDRIVE"
            />
            <ProtectedRoute
                {...routeProps}
                path={[
                    "/integration/fields",
                    "/integration/fields/add",
                    "/integration/fields/edit/:integrationFieldId",
                ]}
                exact
                component={NavigationLayout}
                componentProps={{ component: IntegrationFields }}
                name="INTEGRATION_FIELDS"
            />
            <ProtectedRoute
                {...routeProps}
                path="/botv2/:botId/flows/:flowId"
                exact
                component={NavigationLayout}
                componentProps={{ component: BotV2 }}
                name="BOT_BUILDER"
            />
            <ProtectedRoute
                {...routeProps}
                path={["/botv2/:botId/flows/:flowId/aiFlow", "/botv2/:botId/flows/:flowId/aiFlow/:tabId"]}
                exact
                component={NavigationLayout}
                componentProps={{ component: AIFlowBot }}
                name="BOT_BUILDER"
            />
            <ProtectedRoute
                {...routeProps}
                path="/ctwa-leads"
                exact
                component={NavigationLayout}
                componentProps={{ component: AdsMainPage }}
            />
            <PublicRoute
                {...routeProps}
                path="/users/acceptInvite"
                component={DefaultLayout}
                componentProps={{ component: Invite }}
                name="ACCEPT_USER_INVITE"
            />
            <PublicRoute
                {...routeProps}
                path="/social-sign-up/acceptInvite"
                component={DefaultLayout}
                componentProps={{ component: SocialInvite }}
                name="ACCEPT_USER_INVITE"
            />
            <PublicRoute
                {...routeProps}
                path="/password/update"
                component={DefaultLayout}
                componentProps={{ component: UpdatePassword }}
                name="UPDATE_PASSWORD"
            />
            <ProtectedRoute
                {...routeProps}
                path="/account-basic-info"
                component={DefaultLayout}
                componentProps={{ component: AccountInitialSetup }}
                name="ACCOUNT_BASIC_INFO"
            />
            <ProtectedRoute
                {...routeProps}
                path="/intro"
                component={DefaultLayout}
                componentProps={{ component: OnboardingNew }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/sub-account-onboarding"
                component={DefaultLayout}
                componentProps={{ component: PartnerClientOnboarding }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/get-own-number"
                component={DefaultLayout}
                componentProps={{ component: Connect360Page }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/get-own-number-in-app"
                component={NavigationLayout}
                componentProps={{ component: Connect360PageInApp }}
            />
            <PublicRoute
                {...routeProps}
                path="/password/reset"
                component={DefaultLayout}
                componentProps={{ component: ResetPassword }}
                name="PASSWORD_RESET"
            />
            <PublicRoute
                {...routeProps}
                path="/signup"
                component={DefaultLayout}
                exact
                componentProps={{ component: SignUp }}
                name="ACCOUNT_SIGNUP"
            />
            <PublicRoute
                {...routeProps}
                path="/verifyEmail"
                component={DefaultLayout}
                exact
                componentProps={{ component: SignUpVerifyEmail }}
                name="ACCOUNT_VERIFY_EMAIL"
            />
            <PublicRoute
                {...routeProps}
                path="/logout"
                component={DefaultLayout}
                componentProps={{ component: Logout }}
                name="LOGOUT"
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/dialog360-callback"
                component={NavigationLayout}
                componentProps={{ component: Dialog360Callback }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/getting-started"
                component={NavigationLayout}
                componentProps={{ component: GetStarted }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/payments"
                exact
                component={NavigationLayout}
                componentProps={{ component: Payments }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/payments/config"
                exact
                component={NavigationLayout}
                componentProps={{ component: PaymentsConfig }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/payments/active-exports"
                exact
                component={NavigationLayout}
                componentProps={{ component: PaymentsActiveExport }}
            />
            <ProtectedRoute
                {...routeProps}
                path="/whatsapp-flow-responses/active-exports"
                exact
                component={NavigationLayout}
                componentProps={{ component: WhatsappFlowLogsExport }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/account-suspended"
                component={DefaultLayout}
                componentProps={{ component: ExtendFreeTrial }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/activities"
                component={NavigationLayout}
                componentProps={{ component: AuditLog }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/allowed-ips"
                component={NavigationLayout}
                componentProps={{ component: IPConfig }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/knowledge-base"
                component={NavigationLayout}
                componentProps={{ component: KnowledgeBase }}
            />
            <ProtectedRoute
                {...routeProps}
                exact
                path="/sub-accounts/dashboard"
                component={NavigationLayout}
                componentProps={{ component: PartnerDashboard }}
            />
            <Route path="/oauth-callback" component={OAuthCallback} />
            <Route path="/sso/iframe/callback" component={SSOLoginInIFrame} />
            <Route path="/redirect" exact component={RedirectPage} />
            <Redirect exact from="/" to="/home" />
        </Switch>
    );
};

const popUpRoutes = ["/conversations", "/bots", "/contacts", "getting-started"];

const App: React.FC = () => {
    const [alertModal, setAlertModal] = React.useState<AlertType>(null);

    const popupOpenRef = React.useRef<AlertType>(null);
    const dispatch = useDispatch();
    const authed = useSelector((state: RootState) => state.authed);

    useLocalStorage<{ iid: string }>("config", { iid: uuidv4() }, { storeInitialValue: true });
    const [appLocale, setAppLocale] = useLocalStorage<LanguageIso | undefined>("locale", "en", {});

    useEffect(() => {
        dispatch(initEnvironment());
        dispatch(initAuth());
    }, [dispatch]);

    const contextData = useSelector((state: RootState) => state.authed.user);
    const account = contextData.accounts?.[0]?.account;

    const history = useHistory();

    const { isAdminOrOwner: isAdmin } = useUserRole();

    useBackgroundNotification();

    prdAnalyticsTracker.useInit({
        apiKey: AMPLITUDE_DEVELOPMENT_API_KEY_ENV,
    });

    const sandboxStatus = account?.status;

    const pendingInvoiceDays = React.useMemo(() => {
        if (!account?.invoice || !isAdmin) return null;
        const { status, dueDate } = account.invoice;
        if (status !== "open") return null;
        const days = dayjs().diff(dayjs(dueDate).tz("UTC"), "day");
        if (days <= 0) return null;
        return days;
    }, [account?.invoice, isAdmin]);

    const shouldNotDismiss = React.useMemo(() => {
        return sandboxStatus != null && sandboxStatus === "disconnected";
    }, [sandboxStatus]);

    const isOpen = !!alertModal;

    const onOpen = React.useCallback((type: NonNullable<AlertType>) => {
        setAlertModal(type);
        popupOpenRef.current = type;
    }, []);

    const onClose = React.useCallback(() => {
        setAlertModal(null);
        popupOpenRef.current = null;
    }, []);

    const [isDismissible, setIsDismissible] = React.useState<boolean>(shouldNotDismiss);

    const listenCallBack = React.useCallback(
        (location: Location) => {
            const isModalOpen = popupOpenRef.current === "upgrade";
            const showAlert = popUpRoutes.some((r) => location.pathname.toLowerCase().includes(r));
            if (!showAlert || !sandboxStatus || sandboxStatus === "active") {
                if (isModalOpen) onClose();
                return;
            }
            if (shouldNotDismiss) setIsDismissible(true);
            // onOpen("upgrade");
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sandboxStatus, shouldNotDismiss]
    );

    React.useEffect(() => {
        if (account?.provider !== "chargebee" && account?.managedBy != null) return;

        if (!pendingInvoiceDays || !authed?.accessToken) {
            if (isOpen) onClose();
            return;
        } else if (pendingInvoiceDays <= 14) {
            onOpen("payment_due_7days");
        } else if (pendingInvoiceDays >= 15 && pendingInvoiceDays <= 30) {
            onOpen("payment_due_30days");
        } else if (pendingInvoiceDays >= 31 && pendingInvoiceDays <= 60) {
            onOpen("payment_due_60days");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingInvoiceDays, authed?.accessToken]);

    useEffect(() => {
        const checkHistory = history.listen(listenCallBack);
        listenCallBack(history.location);

        return checkHistory;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
        createAuthRefreshInterceptor(gbAPI, refreshAuthLogic(history));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (contextData.locale && appLocale && contextData.locale !== appLocale) {
            loadLanguage(contextData.locale as SupportedLocaleCodeType);
            setAppLocale(contextData.locale);
        }
    }, [appLocale, contextData.locale, setAppLocale]);

    if (authed?.isAuthChecked) {
        prdAnalyticsTracker.setUser(contextData.id);
        prdAnalyticsTracker.setIdentify(sanitizeUser(contextData));
        return (
            <>
                <NewVersionAlert />
                <ZohoHelpWidget />
                <GBProductTour />
                <AlertNetworkConnection />
                <AuthProvider authed={authed}>
                    <MiitelWidget />
                    <AppRoutes authed={authed} />
                </AuthProvider>
                <AlertModal type={alertModal} onClose={onClose} isDismissible={isDismissible} />
            </>
        );
    }

    return <div />;
};

export default App;
