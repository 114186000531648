import { useUser } from "app/utils/react-helpers";
import React from "react";

const useUserRole = () => {
    const { accounts } = useUser();
    const account = accounts?.[0];

    // FIXME: this is bad ⚠️
    const isAdmin = React.useMemo(() => {
        if (!account?.role?.isActive) return false;
        return account?.role.name?.toLowerCase().includes("admin");
    }, [account?.role?.isActive, account?.role?.name]);

    const isOwner = React.useMemo(() => {
        if (!account?.role?.isActive) return false;
        return account?.role.name?.toLowerCase().includes("owner");
    }, [account?.role?.isActive, account?.role?.name]);

    return {
        isAdminOrOwner: isOwner || isAdmin,
        isAdmin,
        isOwner,
    };
};

export default useUserRole;
