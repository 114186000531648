import { BoxProps, TableCellProps as ChakraTableCellProps } from "@chakra-ui/react";
import { Column, ColumnDef, ColumnOrderState, RowData } from "@tanstack/react-table";
import { SelectionColumnConfig, IndeterminateCheckbox } from ".";
import { FreezeTo, GBColumnDef } from "./TableContext/type";
import React from "react";
import { cloneDeep } from "lodash";

export const reorderColumn = (
    draggedColumnIndex: number,
    targetColumnIndex: number,
    columnOrder: string[]
): ColumnOrderState => {
    columnOrder.splice(targetColumnIndex, 0, columnOrder.splice(draggedColumnIndex, 1)[0] as string);
    return [...columnOrder];
};

export const pageSizeOption = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
];

export const getStickyProps = (
    key: FreezeTo,
    value: number | undefined,
    zIndex: BoxProps["zIndex"] = undefined
): Partial<Pick<BoxProps, "pos" | FreezeTo | "boxShadow" | "zIndex">> => {
    if (value == null) return {};
    return {
        pos: "sticky",
        [key]: `${value}px`,
        zIndex,
    };
};

export const getLeftFrozenColumnShadowStyle = (props: {
    isLast: boolean;
    isShadowVisible: boolean;
    isCellBorderVisible?: boolean;
}): ChakraTableCellProps => {
    if (!props.isLast) return {};
    return {
        _after: {
            position: "absolute",
            top: props.isCellBorderVisible ? 0 : "1px",
            right: 0,
            bottom: "-1px",
            width: "9px",
            transform: "translateX(100%)",
            transition: "box-shadow 0.3s",
            content: "''",
            pointerEvents: "none",
            boxShadow: props.isShadowVisible ? "inset 10px 0 8px -8px rgba(0, 0, 0, 0.15)" : undefined,
        },
    };
};

export const getRightFrozenColumnShadowStyle = (props: {
    isFirst: boolean;
    isShadowVisible: boolean;
    isCellBorderVisible?: boolean;
}): ChakraTableCellProps => {
    if (!props.isFirst) return {};
    return {
        _after: {
            position: "absolute",
            top: props.isCellBorderVisible ? 0 : "1px",
            left: 0,
            bottom: "-1px",
            width: "30px",
            transform: "translateX(-100%)",
            transition: "box-shadow 0.3s",
            content: "''",
            pointerEvents: "none",
            boxShadow: props.isShadowVisible ? "inset -10px 0 8px -8px rgba(0, 0, 0, 0.15)" : undefined,
        },
    };
};
const getSelectionColumn = <T extends RowData>(selectionColumnConfig: SelectionColumnConfig<T>): GBColumnDef<T> => {
    const { dataCyPrefix } = selectionColumnConfig;
    return {
        id: "select",
        isDraggable: false,
        enableColumnFreezing: true,
        isUserInteractiveCell: true,
        enableResizing: false,
        size: 50,
        maxSize: 50,
        header: ({ table }) => (
            <IndeterminateCheckbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
                dataCy={dataCyPrefix ? `${dataCyPrefix}-select-all` : "table-select-all"}
            />
        ),
        cell: ({ row }) => (
            <IndeterminateCheckbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                dataCy={
                    dataCyPrefix ? `${dataCyPrefix}-selection-row-${row?.index}` : `table-selection-row-${row?.index}`
                }
            />
        ),
    };
};

export const sanitizeColumnData = <T extends RowData>(
    columns: GBColumnDef<T>[],
    selectionColumnConfig: SelectionColumnConfig<T> = { enableSelection: false }
): { columns: GBColumnDef<T>[]; isSomeColumnFreezed: boolean } => {
    const columnsCloned = cloneDeep(columns);

    const leftFrozenColumn: GBColumnDef<T>[] = [];
    const nonFrozenColumn: GBColumnDef<T>[] = [];
    const rightFrozenColumn: GBColumnDef<T>[] = [];

    const selectionColumn = getSelectionColumn<T>(selectionColumnConfig);
    const { enableSelection, selectionIndex = 0, ...restSelectionConfig } = selectionColumnConfig;

    if (enableSelection) {
        const index = selectionIndex >= 0 && selectionIndex < columnsCloned.length ? selectionIndex : 0;
        const selectColumn = {
            ...selectionColumn,
            enableColumnFreezing: index == 0 ? true : false,
            ...restSelectionConfig,
        };
        columnsCloned.splice(index, 0, selectColumn);
    }

    columnsCloned.forEach((column) => {
        const { enableColumnFreezing, freezeTo } = column;
        if (!enableColumnFreezing) {
            nonFrozenColumn.push(column);
        } else if (enableColumnFreezing && (!freezeTo || freezeTo === "left")) {
            leftFrozenColumn.push({ ...column });
        } else if (enableColumnFreezing && freezeTo === "right") {
            rightFrozenColumn.push({ ...column });
        }
    });
    const reOrderedColumns = [...leftFrozenColumn, ...nonFrozenColumn, ...rightFrozenColumn];
    const isSomeColumnFreezed = leftFrozenColumn.length > 0 || rightFrozenColumn.length > 0;

    return { columns: reOrderedColumns, isSomeColumnFreezed };
};

export function splitMinMax(minmaxValue: string) {
    // Regular expression to match numbers with optional 'px' or 'auto'
    const regex = /(\d+px|auto)/g;

    // Extracting min and max values
    const values = minmaxValue.match(regex);

    // Parsing extracted values
    const minValue = values?.[0];
    const maxValue = values?.[1];

    return [minValue, maxValue];
}

export const getGridTemplateColumn = <T extends RowData = RowData>(
    columns: ColumnDef<T>[],
    tanstackTableColumn: Column<T>[]
): string => {
    const gridTemplateColumn = tanstackTableColumn.reduce((acc, curr, index) => {
        const { maxSize } = columns[index];
        const size = curr.getSize();
        const maxSizeFinal = maxSize ? `${maxSize}px` : "auto";
        let minSizeFinal = `${size}px`;
        return `${acc} minmax(${minSizeFinal}, ${maxSizeFinal})`;
    }, "" as string);
    return gridTemplateColumn;
};
