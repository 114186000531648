import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useAccount, useUser } from "app/utils/react-helpers";
import React, { useEffect, useRef } from "react";
import { ProductFruits } from "react-product-fruits";
import { productFruits } from "product-fruits";
import { PRODUCT_FRUITS_WORKSPACE_CODE } from "environment";

const isOFC = (countryISO: string) => {
    //India, Saudi Arabia, United Arab Emirates, Brazil
    const featuredCountries = ["IND", "SAU", "ARE", "BRA"];
    return !featuredCountries.includes(countryISO);
};

const GBProductTour = () => {
    const user = useUser();
    const account = useAccount();

    const { isFreeTrial, isSandboxChannel, isNewAccount, isSubscribed } = useAccountMasterHook();

    const initRef = useRef(false);

    const userInfo = React.useMemo(() => {
        return {
            username: user.id,
            email: user.email,
            firstname: user.name,
            props: {
                ...(account as unknown as Record<string, any>),
                isSandbox: isSandboxChannel,
                isTrial: isFreeTrial,
                isSubscribed: isSubscribed,
                isOFC: account?.countryIso2 ? isOFC(account.countryIso2?.iso) : false,
            },
        };
    }, [account, isFreeTrial, isSandboxChannel, isSubscribed, user.email, user.id, user.name]);

    const disableTour = !account || !user || Object.keys(user).length < 1 || isSubscribed || isNewAccount;

    useEffect(() => {
        if (disableTour && userInfo.username && !initRef.current) {
            productFruits.init(PRODUCT_FRUITS_WORKSPACE_CODE, "en", { ...userInfo });
            initRef.current = true;
        }
    }, [disableTour, userInfo]);

    if (!disableTour || !userInfo.username) return null;

    return <ProductFruits workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE} language="en" user={userInfo} />;
};

export default GBProductTour;
