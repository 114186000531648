import { BodyFormValues } from "app/screens/Account/Integration/MappingIntegrationTemplateCommon";
import { Channel, Creator, IntegrationSubFeatures, LabelValue, WAITemplateMessage, WATemplate } from "app/types/index";
import { PromptAction } from "../bot";
import { WCAbandonedCart } from "./abandoncart";
import { CashFreeIntegration } from "./cashfree";
import { CleverTapIntegration } from "./cleverTap";
import { IFBLeadsIntegration } from "./fbLeads";
import { LeadSquaredIntegration } from "./leadsquared";
import { IRazorPay } from "./razorPay";
import { ShopfloIntegration, ShopfloWorkFlow } from "./shopFlo";
import { IShopifyIntegration } from "./shopify";
import { StripeIntegration, StripeWorkflow } from "./stripe";
import { IWooCommerceIntegration } from "./woocommerce";
import { WCCartFlow } from "./woocommerceCartFlow";
import { ZohoBooksIntegration } from "./zohoBooks";

const IntegrationTypes = [
    "shopify",
    "fbleads",
    "woocommerce",
    "webengage",
    "moengage",
    "razorpay",
    "hubspot",
    "cashfree",
    "leadsquared",
    "genericWebhook",
    "shiprocket",
    "woocommerce_cartflow",
    "shipway",
    "zohoCRM",
    "woocommerce_abandoned_cart",
    "zohobooks",
    "calendly",
    "cleverTap",
    "stripe",
    "shopflo",
    "miitel",
    "fyno",
    "pipedrive",
] as const;

const ExternalIntegrationTypes = ["gsheet", "kylas", "pabbly", "zapier", "zoho_widget", "sangam_crm"] as const;

export type IntegrationType = (typeof IntegrationTypes)[number];
export type ExternalIntegrationType = (typeof ExternalIntegrationTypes)[number];

export const TRIGGER_TYPES = ["sendWATemplateMessage"] as const;
export type TriggerType = (typeof TRIGGER_TYPES)[number];

export interface IWebEngageIntegration {
    name: string;
    apiKey: string;
    authToken?: string;
    statusUrl: string;
}

export interface IMoEngageIntegration {
    brandName: string;
    statusUrl?: string;
    channelId?: string;
    apiSecret: string;
    channel?: Channel;
}

export interface IFynoIntegration {
    brandName: string;
    statusUrl?: string;
    channelId?: string;
    apiSecret: string;
    channel?: Channel;
}

export interface HubspotIntegration {
    user: string;
    hubDomain: string;
    hubId: number;
    appId: number;
    userId: number;
    credentialId: string;
}

export interface PipedriveIntegration {
    name: string;
}

type NonEmptyArray<T> = [T, ...T[]];

export interface WorkflowConditionProperties {
    fact: "genericWebhook";
    operator: string;
    value: string;
    path: string;
    meta?: {
        pathName: string;
        operatorName: string;
        pathType?: string;
    };
}

// eslint-disable-next-line no-use-before-define
type NestedCondition = WorkflowConditionProperties | WorkflowConditions;
type AllConditions = { all: NonEmptyArray<NestedCondition> };
type AnyConditions = { any: NonEmptyArray<NestedCondition> };
export type WorkflowConditions = AllConditions | AnyConditions;

export interface RecipientInfo {
    recipientNumber: string;
    recipientName: string;
}

export interface SendWATemplateMessageTriggerWebHook {
    templateId: string;
    template?: WATemplate;
    templateValues: WATemplateValues;
    recipient: RecipientInfo;
}

export const ACTION_TYPES = ["sendWATemplateMessage"] as const;
export type ActionType = (typeof ACTION_TYPES)[number];

export interface GenericWebhookWorkFlow {
    name: string;
    isActive?: boolean;
    action?: ActionType;
    sendWATemplateMessage?: SendWATemplateMessageTriggerWebHook;
    condition?: WorkflowConditions;
    id?: string;
    workflowActions?: PromptAction[];
}

export interface GenericWebhookWorkFlowResponse {
    name: string;
    id: string;
    isActive: boolean;
    sendWATemplateMessage?: SendWATemplateMessageTriggerWebHook;
}

export interface GenericWebhookData {
    headers: Record<string, any>;
    body: Record<string, any>;
}

export interface GenericWebhook {
    webhookUrl: string;
    brandName: string;
    channelId: string;
    channel?: Channel;
    workflows?: GenericWebhookWorkFlow[];
    sampleResponseFlat: GenericWebhookData | null;
    isConfigured: boolean;
}

export interface SocketSampleResponse {
    integration: Integration;
}

export const shiprocketStatusNames = [
    "shipped",
    "delivered",
    "out_for_delivery",
    "picked_up",
    "pickup_scheduled",
] as const;
export type ShiprocketStatusNameType = (typeof shiprocketStatusNames)[number];

export interface ShiprocketStats {
    total: number;
    success: number;
    failed: number;
}

type WATemplateValues = Partial<Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">>;

export interface ShiprocketEventTriggers {
    templateId: string;
    templateValues?: WATemplateValues;
    isActive: boolean;
    workflowActions?: PromptAction[];
}

export interface ShipRocketIntegration {
    webhookUrl: string;
    webhookSecret: string;
    brandName: string;
    credentialId: string;
    channelId: string;
    channel?: Channel;
    stats: ShiprocketStats;
    triggers?: {
        [event in ShiprocketStatusNameType]: ShiprocketEventTriggers;
    };
}

export const shipwayStatusCodes = ["DEL", "PKP", "OOD"] as const;
export type ShipwayStatusCode = (typeof shipwayStatusCodes)[number];

interface ShipwayStats {
    total: number;
    success: number;
    failed: number;
}
export interface CalendlyStats {
    total: number;
    success: number;
    failed: number;
}

export interface ShipwayEventTriggers {
    templateId: string;
    templateValues: WATemplateValues;
    isActive: boolean;
}

export interface ShipwayIntegration {
    webhookUrl: string;
    brandName: string;
    credentialId: string;
    channelId: string;
    channel?: Channel;
    stats: ShipwayStats;
    workflows?: {
        [event in ShipwayStatusCode]?: ShipwayEventTriggers;
    };
}

export interface ZohoCRMConfig {
    contact?: {
        syncOut: boolean;
        syncAs: "lead" | "contact";
    };
}

export interface ZohoCRMIntegration {
    credentialId: string;
    integrationName: string;
    channelId?: string[];
    enableSignals: boolean;
    channels?: Channel[];
    accessToken?: string;
    crmConfig: ZohoCRMConfig;
}
export const calendlyEvents = ["invitee_created", "invitee_canceled"] as const;
export type CalendlyEventType = (typeof calendlyEvents)[number];

export interface CalendlyWorkflow {
    readonly id: string;
    events: CalendlyEventType;
    isActive: boolean;
    eventTypeUri: LabelValue[]; // product demo, 30 mins meeting
    duration: number;
    remind: "before" | "now" | "after";
    templateId: string;
    recipient: RecipientInfo;
    templateValues: BodyFormValues;
    workflowActions: PromptAction[];
    useCase: "reminder" | "followup" | "confirmation";
}

export interface Workflow {
    readonly id: string;
    accountId: string;
    integrationId: string;
    isActive: boolean;
    type: string;
    calendly?: {
        events: CalendlyEventType;
        eventTypeUri: LabelValue[];
        duration: number;
        remind: "before" | "now" | "after";
        useCase: "reminder" | "followup" | "confirmation";
        recipient: RecipientInfo;
    };
    stripe?: StripeWorkflow;
    shopflo?: ShopfloWorkFlow;
    templateId: string;
    templateValues: WATemplateValues;
    workflowActions: PromptAction[];
}

export interface ICalendlyIntegration {
    webhookUrl: string;
    webhookSecret: string;
    credentialId?: string;
    integrationName: string;
    organization: string;
    channelId: string;
    channel?: Channel;
    stats: CalendlyStats;
    workflows: Workflow[];
}

export interface MiitelChannelConfig {
    circuitNumber: string;
    channelId: string;
    channel?: Channel;
}

export interface MiitelIntegration {
    config: MiitelChannelConfig[];
    accessToken: string;
    company: string;
    companyId: string;
}

export interface Integration extends Creator {
    id?: string;
    accountId: string;
    creatorId: string;
    type: IntegrationType;
    shopify?: IShopifyIntegration;
    calendly?: ICalendlyIntegration;
    woocommerce?: IWooCommerceIntegration;
    fbleads?: IFBLeadsIntegration;
    webengage?: IWebEngageIntegration;
    moengage?: IMoEngageIntegration;
    hubspot?: HubspotIntegration;
    genericWebhook?: GenericWebhook;
    shiprocket?: ShipRocketIntegration;
    shipway?: ShipwayIntegration;
    razorpay?: IRazorPay;
    leadsquared?: LeadSquaredIntegration;
    cashfree?: CashFreeIntegration;
    woocommerce_cartflow?: WCCartFlow;
    woocommerce_abandoned_cart: WCAbandonedCart;
    stripe?: StripeIntegration;
    zohobooks?: ZohoBooksIntegration;
    zohoCRM?: ZohoCRMIntegration;
    cleverTap?: CleverTapIntegration;
    shopflo?: ShopfloIntegration;
    miitel?: MiitelIntegration;
    createdAt?: string;
    updatedAt?: string;
    isUnInstalled?: boolean;
    fyno?: IFynoIntegration;
    pipedrive?: PipedriveIntegration;
}

const businessAppGroup = ["crm", "marketing", "payments", "others", "leads", "order-management"] as const;
export type BusinessAppGroupType = (typeof businessAppGroup)[number];

export interface IntegrationListType {
    image: string;
    linkURL?: string;
    title: string;
}

export interface IntegrationBase {
    id?: string;
    accountId: string;
    creatorId: string;
    type: IntegrationType;
    createdAt?: string;
    updatedAt?: string;
    isUnInstalled?: boolean;
}

export enum IntegrationTypeV2 {
    Shopify = "shopify",
    WooCommerce = "woocommerce",
    FBLeads = "fbleads",
    WebEngage = "webengage",
    HubSpot = "hubspot",
    GenericWebhook = "genericWebhook",
    ShipRocket = "shiprocket",
    RazorPay = "razorpay",
    LeadSquared = "leadsquared",
    CashFree = "cashfree",
    Shipway = "shipway",
    woocommerce_cartflow = "woocommerce_cartflow",
    zohoCRM = "zohoCRM",
    woocommerce_abandoned_cart = "woocommerce_abandoned_cart",
    zohoBooks = "zohobooks",
    Calendly = "calendly",
    CleverTap = "cleverTap",
    Stripe = "stripe",
    Moengage = "moengage",
    Shopflo = "shopflo",
    Miitel = "miitel",
    Fyno = "fyno",
    pipedrive = "pipedrive",
}

export type IntegrationV2 = IntegrationBase & IntegrationDetails;

export interface IntegrationDetails {
    [IntegrationTypeV2.Shopify]?: IShopifyIntegration;
    [IntegrationTypeV2.WooCommerce]?: IWooCommerceIntegration;
    [IntegrationTypeV2.FBLeads]?: IFBLeadsIntegration;
    [IntegrationTypeV2.WebEngage]?: IWebEngageIntegration;
    [IntegrationTypeV2.HubSpot]?: HubspotIntegration;
    [IntegrationTypeV2.GenericWebhook]?: GenericWebhook;
    [IntegrationTypeV2.ShipRocket]?: ShipRocketIntegration;
    [IntegrationTypeV2.RazorPay]?: IRazorPay;
    [IntegrationTypeV2.LeadSquared]?: LeadSquaredIntegration;
    [IntegrationTypeV2.CashFree]?: CashFreeIntegration;
    [IntegrationTypeV2.Shipway]?: ShipwayIntegration;
    [IntegrationTypeV2.woocommerce_cartflow]?: WCCartFlow;
    [IntegrationTypeV2.zohoCRM]?: ZohoCRMIntegration;
    [IntegrationTypeV2.woocommerce_abandoned_cart]?: WCAbandonedCart;
    [IntegrationTypeV2.zohoBooks]?: ZohoBooksIntegration;
    [IntegrationTypeV2.Calendly]?: ICalendlyIntegration;
    [IntegrationTypeV2.CleverTap]?: CleverTapIntegration;
    [IntegrationTypeV2.Stripe]?: StripeIntegration;
    [IntegrationTypeV2.Moengage]?: IMoEngageIntegration;
    [IntegrationTypeV2.Shopflo]?: ShopfloIntegration;
    [IntegrationTypeV2.Miitel]?: MiitelIntegration;
    [IntegrationTypeV2.Fyno]?: IFynoIntegration;
    [IntegrationTypeV2.pipedrive]?: PipedriveIntegration;
}

export const INTEGRATIONS_CATEGORY_MAPPING: Record<IntegrationType, IntegrationSubFeatures> = {
    calendly: "integrations_intermediate",
    cashfree: "integrations_intermediate",
    cleverTap: "integrations_advanced",
    fbleads: "integrations_basic",
    genericWebhook: "integrations_basic",
    hubspot: "integrations_intermediate",
    leadsquared: "integrations_advanced",
    moengage: "integrations_advanced",
    razorpay: "integrations_basic",
    shiprocket: "integrations_basic",
    shipway: "integrations_intermediate",
    shopify: "integrations_basic",
    webengage: "integrations_advanced",
    woocommerce: "integrations_basic",
    woocommerce_abandoned_cart: "integrations_basic",
    woocommerce_cartflow: "integrations_basic",
    zohobooks: "integrations_basic",
    zohoCRM: "integrations_basic",
    stripe: "integrations_advanced",
    shopflo: "integrations_basic",
    miitel: "integrations_basic",
    pipedrive: "integrations_basic",
    fyno: "integrations_basic",
};
