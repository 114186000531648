import {
    VStack,
    Heading,
    HStack,
    Box,
    Icon,
    Image,
    Text,
    IconProps,
    Link,
    Button,
    Spacer,
    Stack,
    useDisclosure,
} from "@chakra-ui/react";
import { boldText, isJSXElement, windowOpenNewTab } from "app/utils/common";
import React from "react";
import { IconType } from "react-icons";
import { MdDone, MdOutlineHelpOutline } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";
import { OnboardingQAType, OnboardingQuestionType } from "./QandA/type";
import { Link as RouterLink } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import BackIconButton from "app/components/Button/BackIconButton";
import Dialog360Steps from "./Dialog360Steps";
import { supportDemoURL } from "app/contants";
import OnboardingHelp from "./OnboardingHelp";
import GBTo360ConnectImg from "assets/onboarding/free-trial/gb-to-360-connection.svg";
import GBTo360ConnectImgMob from "assets/onboarding/free-trial/gb-to-360-connection-mobile.svg";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { IoAlertOutline } from "react-icons/io5";
import Lottie from "lottie-react";
import DownArrowAnimation from "assets/lottieJSON/onboarding-down-arrow.json";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import Connect360ButtonOnboarding from "./component/Connect360ButtonOnboarding";
import GetAssistanceModal from "./GetAssistanceModal";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import LearnMoreLink from "app/components/LearnMoreLink";
import { LuExternalLink } from "react-icons/lu";
import { useUser } from "app/utils/react-helpers";

interface ValidateConnectProps {
    userAnswer: OnboardingQAType;
    type: "modal" | "onboarding" | "in-app";
    onBack?: () => void;
    connectAnyWay?: boolean;
}
type TrueFalse = "true" | "false" | "exception" | "not_questioned";
type StringOrJSX = string | JSX.Element;

const messages = new Map<OnboardingQuestionType, Record<TrueFalse, StringOrJSX>>([
    [
        "whatsapp-api",
        {
            true: "I have the phone number to connect with WhatsApp business API Handy.",
            false: "I don’t have the phone number to connect to WhatsApp business API handy.",
            not_questioned: "Keep your business phone number handy to receive OTP!",
            exception: "Make sure you have the phone number to connect to WhatsApp business API handy.",
        },
    ],
    [
        "already-linked",
        {
            true: "Currently I am not using WhatsApp with that number.",
            false: "I’m currently using my WhatsApp with that number and I need time to backup",
            not_questioned: (
                <Text color="gray.700" fontSize="sm">
                    If you are currently using WhatsApp with that number, {boldText("backup and remove it")}.
                </Text>
            ),
            exception: "Confirm that you are not using WhatsApp with that number",
        },
    ],
    [
        "fb-manager-access",
        {
            true: "I have the access to Facebook Business Manager Account.",
            false: "I don’t have access to Facebook Business Manager account.",
            not_questioned: "Keep your Facebook Business Manager account ready.",
            exception: "Ensure you have access to Facebook Business Manager account.",
        },
    ],
]);

export interface CircularCompProps {
    color: IconProps["color"];
    icon: IconType;
}

export const IconAndColor = new Map<TrueFalse, CircularCompProps>([
    ["true", { icon: MdDone, color: "green.400" }],
    ["false", { icon: RiCloseFill, color: "red.400" }],
    ["not_questioned", { icon: MdDone, color: "green.400" }],
    ["exception", { icon: IoAlertOutline, color: "yellow.400" }],
]);

export const CircularComp: React.FC<CircularCompProps> = (props) => {
    const { color, icon } = props;
    return (
        <Box borderWidth="2.5px" rounded="full" w={7} h={7} flexShrink={0} borderColor={color} mx="auto" pos="relative">
            <Box pos="absolute" left="8%" top="8%" boxSize={5}>
                <Icon as={icon} color={color} fontSize="20px" w="full" h="full" />
            </Box>
        </Box>
    );
};

const ValidateConnect: React.FC<ValidateConnectProps> = (props) => {
    const { userAnswer, type, connectAnyWay = false } = props;
    const [showLottie, setShowLottie] = React.useState(true);

    const { isMobile } = useGBMediaQuery();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOFC, isSubAccount } = useAccountMasterHook();

    const isModal = type === "modal";
    const isInApp = type === "in-app";
    const isOnboarding = type === "onboarding";

    const userAnsArray = Object.entries(userAnswer) as Array<[OnboardingQuestionType, boolean]>;
    const isAllPositiveAns = userAnsArray.every(([key, val]) => val);
    const canIConnect = connectAnyWay || isAllPositiveAns;
    // const headerText = connectAnyWay
    //     ? isAllPositiveAns
    //         ? "😃 You are ready to connect your number "
    //         : "Make sure you have the following to proceed!"
    //     : isAllPositiveAns
    //     ? "😃 You are ready to connect your number "
    //     : "😥 Seems like you are not ready to connect your number";
    const headerText = "Guide to connect your number to WhatsApp Business API";
    const contentComp = React.useCallback((val: StringOrJSX) => {
        if (isJSXElement(val)) return val;
        return (
            <Text color="gray.700" fontSize="sm">
                {val}
            </Text>
        );
    }, []);

    const footerComp = React.useCallback(
        (disableLottie: boolean = false) => {
            if (canIConnect) {
                return (
                    <Stack
                        direction={{ base: "column", sm: "row", lg: "row" }}
                        pos={{ base: "fixed", sm: "relative", lg: "relative" }}
                        bottom={{ base: "0px", sm: "unset", lg: "unset" }}
                        bg={{ base: "white", sm: "unset", lg: "unset" }}
                        px={{ base: 5, sm: "unset", lg: "unset" }}
                        py={{ base: 2, sm: "unset", lg: "unset" }}
                        spacing={{ base: 1.5, sm: "2", lg: "2" }}
                        justify={{ base: "unset", sm: "end", lg: "end" }}
                        w="full"
                        zIndex={1}
                    >
                        {!disableLottie && (
                            <Button
                                size="sm"
                                w={{ base: "full", sm: "unset", lg: "unset" }}
                                variant="outline"
                                bg="white"
                                role="group"
                                color="gray.700"
                                py={{ base: 5, sm: "unset", lg: "unset" }}
                                fontWeight="semibold"
                                as={Link}
                                href={supportDemoURL}
                                target="_blank"
                                _hover={{ textDecor: "none" }}
                                data-tracking-event={isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP"}
                                onClick={() => {
                                    trackEvent({ event: isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP" });
                                }}
                            >
                                Get expert assistance&nbsp;&nbsp;🎧
                            </Button>
                        )}
                        {isOFC && !disableLottie && (
                            <LearnMoreLink
                                module="on-boarding"
                                titleName="Get Own Number"
                                buttonType="button"
                                size="medium"
                                colorScheme="brand"
                                link="https://docs.gallabox.com/connect-whatsapp-channel/connect-your-whatsapp"
                                leftIcon={<Icon as={MdOutlineHelpOutline} fontSize="md" />}
                            >
                                Help Docs
                            </LearnMoreLink>
                        )}

                        {disableLottie && (
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                alignSelf={{ base: "center", sm: "center", lg: "center" }}
                            >
                                Need help? Connect with{" "}
                                <Link
                                    href={supportDemoURL}
                                    target="_blank"
                                    color="blue.500"
                                    data-tracking-event={isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP"}
                                    onClick={() => {
                                        trackEvent({ event: isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP" });
                                    }}
                                >
                                    our team
                                </Link>
                            </Text>
                        )}

                        {disableLottie && <Spacer />}
                        <Connect360ButtonOnboarding
                            isOnboarding={isOnboarding}
                            buttonText="Connect By Yourself ➡️"
                            onClick={() => setShowLottie(false)}
                            variant="primary"
                        />
                        {showLottie && !disableLottie && !isMobile && (
                            <Box
                                pos="absolute"
                                transform="scale(-1, 1)"
                                height="40px"
                                width="35px"
                                right="115px"
                                top="35px"
                            >
                                <Box transform="rotate(-45deg)">
                                    <Lottie animationData={DownArrowAnimation} loop={true} />
                                </Box>
                            </Box>
                        )}
                    </Stack>
                );
            }
            return (
                <Stack
                    direction={{ base: "column", sm: "row", lg: "row" }}
                    pos={{ base: "fixed", sm: "unset", lg: "unset" }}
                    bottom={{ base: "5px", sm: "unset", lg: "unset" }}
                    px={{ base: 5, sm: "unset", lg: "unset" }}
                    py={{ base: 2, sm: "unset", lg: "unset" }}
                    w="full"
                    justify="end"
                    spacing={{ base: 1.5, sm: 5, lg: 5 }}
                    bg={{ base: "white", sm: "unset", lg: "unset" }}
                    zIndex={1}
                >
                    <Button
                        size="sm"
                        variant="outline"
                        bg="white"
                        role="group"
                        color="gray.700"
                        fontWeight="semibold"
                        rightIcon={<Icon as={BiRightArrowAlt} fontSize="20px" />}
                        as={RouterLink}
                        to={isModal ? "/get-own-number-in-app" : "/sandbox"}
                        data-event="FALLBACK_SBX_ACTIVATE"
                        py={{ base: 5, sm: "unset", lg: "unset" }}
                        onClick={() => {
                            if (isModal) return;
                            trackEvent({ event: "FALLBACK_SBX_ACTIVATE" });
                        }}
                    >
                        {isModal ? "Connect your number" : "Try demo account"}
                    </Button>
                    <Button
                        as={Link}
                        href={supportDemoURL}
                        target="_blank"
                        size="sm"
                        colorScheme="brand"
                        role="group"
                        py={{ base: 5, sm: "unset", lg: "unset" }}
                        _hover={{ textDecor: "none" }}
                        data-tracking-event={isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP"}
                        onClick={() => {
                            trackEvent({ event: isInApp ? "ONBOARD_NEED_HELP_SBX" : "ONBOARD_NEED_HELP" });
                        }}
                    >
                        Talk to our expert&nbsp;&nbsp;🎧
                    </Button>
                </Stack>
            );
        },
        [canIConnect, isInApp, isMobile, isModal, isOFC, isOnboarding, showLottie]
    );

    const containerProps = {
        bg: { base: "transparent", sm: "white", lg: "white" },
        boxShadow: { base: "none", sm: "base", lg: "base" },
        w: { base: "full", sm: "container.sm", lg: "container.sm" },
        py: { base: 2, sm: 10, lg: 10 },
        px: { base: 5, sm: 10, lg: 10 },
        rounded: "md",
        spacing: 10,
    };
    return (
        <VStack
            w="full"
            h={{ base: "calc(100% - 60px)", sm: "full", lg: "full" }}
            overflow="auto"
            spacing={{ base: 2, sm: 5, lg: 5 }}
            pb={24}
        >
            <VStack {...(isInApp || !isModal ? containerProps : {})} h="full" spacing={{ base: 5, sm: 10, lg: 10 }}>
                <HStack w="full" justify={{ base: "center", sm: "unset", lg: "unset" }}>
                    <Box
                        flex={1}
                        pos={{ base: "fixed", sm: "unset", lg: "unset" }}
                        top={{ base: "20px", sm: "unset", lg: "unset" }}
                        left={{ base: "20px", sm: "unset", lg: "unset" }}
                    >
                        {props.onBack && <BackIconButton onClick={props.onBack} />}
                    </Box>
                    <Heading color="gray.800" fontSize="lg" fontWeight="semibold" textAlign="center">
                        {headerText}
                    </Heading>
                    {!isMobile && <Box flex={1} />}
                </HStack>
                <Stack
                    direction={{ base: "column", sm: "row", lg: "row" }}
                    w="full"
                    h="full"
                    align="center"
                    spacing={8}
                >
                    <Box
                        w={{ base: "full", sm: "204px", lg: "204px" }}
                        h={{ base: "172px", sm: "200px", lg: "200px" }}
                        overflow="hidden"
                    >
                        <Image
                            src={isMobile ? GBTo360ConnectImgMob : GBTo360ConnectImg}
                            w="full"
                            h="full"
                            fallback={<Box w="full" h="full" bg="gray.300" />}
                        />
                    </Box>
                    <VStack spacing="5" w="full" align="start" h="full" justify="center" flex={1}>
                        {userAnsArray.map((ans, idx) => {
                            const [key, value] = ans;
                            const valueKey = "not_questioned";
                            // const valueKey = (connectAnyWay
                            //     ? value
                            //         ? "true"
                            //         : "exception"
                            //     : value.toString()) as TrueFalse;
                            const content = messages.get(key)?.[valueKey];
                            return (
                                <HStack key={idx} justify="start">
                                    <CircularComp {...(IconAndColor.get(valueKey) as CircularCompProps)} />
                                    {content && contentComp(content)}
                                </HStack>
                            );
                        })}
                    </VStack>
                </Stack>
                {footerComp()}
            </VStack>
            {!isSubAccount && canIConnect && (
                <Box pos="fixed" bottom={{ base: "120px", sm: "10px", lg: "10px" }} right="10px" zIndex={10}>
                    <OnboardingHelp />
                </Box>
            )}
            {!canIConnect && !isModal && (
                <Text fontSize="sm" color="gray.600">
                    Continue to{" "}
                    <Link
                        as={RouterLink}
                        to="/get-own-number"
                        color="brand.500"
                        data-tracking-event="FALLBACK_CONNECT_NUMBER"
                        onClick={() => {
                            trackEvent({ event: "FALLBACK_CONNECT_NUMBER" });
                        }}
                    >
                        connect your number {isMobile ? "-->" : ""}
                    </Link>
                </Text>
            )}
            {(canIConnect || isOnboarding) && <Dialog360Steps />}
            {canIConnect && !isMobile && (
                <HStack w="container.md" bg="white" px={7} py={6} rounded="md" boxShadow="base" zIndex={0}>
                    {footerComp(true)}
                </HStack>
            )}
            {canIConnect && !isInApp && (
                <Text fontSize="sm" color="gray.500" fontWeight="medium">
                    Explore Gallabox with{" "}
                    <Link
                        as={RouterLink}
                        color="blue.500"
                        to="/sandbox"
                        data-tracking-event="FALLBACK_SBX_ACTIVATE"
                        onClick={() => {
                            trackEvent({ event: "FALLBACK_SBX_ACTIVATE" });
                        }}
                    >
                        demo account
                    </Link>
                </Text>
            )}
            <GetAssistanceModal isOpen={isOpen} onClose={onClose} />
        </VStack>
    );
};

export default ValidateConnect;
