import { useToast } from "@chakra-ui/react";
import { AvailabilityType, QueryKey } from "app/types/common";
import { LoggedInUser, User, UserAccount } from "app/types/user";
import { fetcher, patchJSON } from "app/utils/fetchUtils";
import { QueryFunction, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";

interface UserAvailabilityProps {
    accountId: string;
    userId?: string;
    onSuccess?: (data: UserAccount) => void;
    onError?: (data?: User) => void;
}
interface UpdateUserAvailabilityProps {
    availability: AvailabilityType;
    userId?: string;
}

type UserMeType = UseQueryResult<LoggedInUser, Error>;

export type UserAvailabilityReturnType = UseMutationResult<UserAccount, Error, UpdateUserAvailabilityProps>;

export const useUserAvailability = (props: UserAvailabilityProps): UserAvailabilityReturnType => {
    const { accountId, userId, onSuccess, onError } = props;
    const queryClient = useQueryClient();

    const updateUserAvailability = (props: UpdateUserAvailabilityProps) => {
        const { availability, userId: uId } = props;
        const id = uId || userId;
        return patchJSON<UpdateUserAvailabilityProps, UserAccount>(
            `/api/accounts/${accountId}/users/${id}/availability?version=v2`,
            { availability }
        );
    };

    const toast = useToast();

    const mutation = useMutation<UserAccount, Error, UpdateUserAvailabilityProps>(updateUserAvailability, {
        onSuccess: (data) => {
            queryClient.setQueryData<UserMeType["data"]>([QueryKey.UserAPIMe], (oldData) => {
                const copiedData = JSON.parse(JSON.stringify(oldData));
                const isCurrentUser = copiedData?.id === data?.userId;
                if (isCurrentUser && copiedData?.accounts?.[0]) {
                    copiedData.accounts[0].availability = data?.availability;
                }
                return copiedData;
            });
            queryClient.invalidateQueries([QueryKey.UserList, { accountId }]);
            toast({
                title: "User Availability Status is Updated!",
                status: "success",
            });
            onSuccess?.(data);
        },
        onError: () => {
            onError?.();
        },
    });
    return mutation;
};

const fetchUser: QueryFunction<LoggedInUser> = () => {
    return fetcher("/api/me");
};
export const useAPIMe = (): UserMeType => {
    const query = useQuery<LoggedInUser, Error>([QueryKey.UserAPIMe], fetchUser);
    return query;
};
